<template>
  <div class="hospital-add">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dashboard/hospital' }">医院管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加医院</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-row>
        <el-form :model="addForm" :rules="addRules" ref="addRef" label-width="100px" class="add-form">
          <el-form-item label="医院名称" prop="title">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="医院地址" prop="address">
            <el-input v-model="addForm.address"></el-input>
          </el-form-item>
          <el-form-item label="医院图片" prop="img">
            <upload :url="addForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="addForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="医院坐标" prop="coord">
            <el-input v-model="addForm.coord">
              <el-button slot="append" type="primary" @click="pick">点击拾取</el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('addRef')">立即创建</el-button>
            <el-button @click="backUrl">返回</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'HospitalAdd',
  components: {
    Upload
  },
  data () {
    return {
      addForm: {
        title: '',
        address: '',
        img: '',
        phone: '',
        coord: ''
      },
      addRules: {
        title: [
          { required: true, message: '请输入医院名称', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入医院地址', trigger: 'blur' },
          { min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传医院图片', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        coord: [
          { required: true, message: '请输入医院坐标', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    backUrl () {
      this.$router.push('/dashboard/hospital')
    },
    // 拾取坐标
    pick () {
      window.open('https://lbs.qq.com/getPoint/', '_blank')
    },
    // 提交表单
    async submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/hospital/0', this.addForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.$router.push('/dashboard/hospital')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  padding: 60px;
}
</style>
